<script setup>
import Alert from '@/components/popup/Alert';
import BottomDialog from '@/components/popup/BottomDialog';
import MapPin from '@/assets/img/icons/map-pin.svg';
import { ref, reactive, onMounted, computed, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { defineType } from '@/utils/validation-utils';
import { getOrgInfo, saveOrgInfo } from '@/api/orginfo/orginfo';
import { getSuggestionsByPosition, getSuggestionsByAddress, getCoordsByAddress } from '@/api/dadata';
import { getUser } from '@/utils/auth-header';

const router = useRouter();
const route = useRoute();

const mapInfo = reactive({
  settings: {
    apiKey: process.env.VUE_APP_YANDEX_API_KEY,
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
  },
  marker: {
    layout: 'default#image',
    imageHref: MapPin,
    imageSize: [44, 45],
    imageOffset: [-22, -55],
  },
  smallMarker: {
    layout: 'default#image',
    imageHref: MapPin,
    imageSize: [24, 24],
    imageOffset: [-15, -25],
  },
  smallMarkerEvents: [],
  controls: [],
  options: { yandexMapDisablePoiInteractivity: true },
  events: ['click'],
  behaviors: ['drag', 'scrollZoom'],
  zoom: 17
});

const contactInfo = reactive ({
  email: '',
  phoneNumbers: [],
  webSite: '',
  address: '',
  coords: [54.62929408, 39.73669342]
});
const addressInfo = reactive({
  addressSuggestions: [],
  hideDropdown: true
});
const previewInfo = reactive ({
  orgIconType: 0,
  orgIcon: `icon-org-default.svg`,
  orgName: 'Организация',
  addressWithPostIndex: ''
});

const counter = ref(1);
const maxNumOfPhoneNumbers =  5;

const user = ref(null);
const organization = ref(null);
const loader = ref(true);
const confirm = ref(null);

function activateTab(){
  if (route.query.tab){
    const tabName = route.query.tab;
    document.querySelector('.nav-item .active').classList.remove('active');
    document.querySelector('#'+tabName+'-tab').classList.add('active');
    document.querySelector('.tab-content .tab-pane').classList.remove('active');
    document.querySelector('#'+tabName).classList.add('active');
  }
}

async function _getOrgInfo() {
  let result = await getOrgInfo(user.value.orgId);
  if (result.error === false) {
    organization.value = result;
    contactInfo.address = result.address;
    
    let phoneNumbers = result.phoneNumbers;
    let emails = result.emails;
    
    if (phoneNumbers.length > 0)
      counter.value = phoneNumbers.length;
    else 
      counter.value = 1;
    
    contactInfo.phoneNumbers = phoneNumbers.map(p => formatPhoneNumber(p));
    contactInfo.email = emails[0];
    contactInfo.webSite = result.webSite;
    
    previewInfo.orgName = result.name;
    previewInfo.orgIconType = result.imageTypeId;
    
    await initMap(result.geoXCoordinate, result.geoYCoordinate);
    formatAddressWithPostIndex();
  } else {
    emptyMap();
  }
  mapOrgIcon();
  loader.value = false;
}

async function initMap(geoX, geoY) {
  if (geoX && geoY) {
    contactInfo.coords = [geoY, geoX];
    return;
  } 
  if (!contactInfo.address) {
    emptyMap();
  } else {
    await _getCoordsByAddress();
  }
}

async function _getCoordsByAddress() {
  let result = await getCoordsByAddress(contactInfo.address);
  if (!result.error) {
    contactInfo.coords = [result.lat, result.lon];
  } else {
    emptyMap();
    await confirm.value.show(
        'Не были получены координаты. Отображение на карте может работать некорректно.',
        null,
        false
    );
  }
}

function emptyMap() {
  mapInfo.marker.imageHref = "";
  contactInfo.coords = [54.62929408, 39.73669342];
}

function formatPhoneNumber(phoneNumberString) {
  let ryazanCode = '4912';
  let regex = new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\d?\\)?[\\- ]?)?([\\d\\- ]{6,9}\\d?)$');
  
  let matches = phoneNumberString.match(regex);
  let phoneValue = '';
  if (matches) {
    phoneValue = matches[0];
    if (matches[3] && matches[4])
      phoneValue = matches[3] + matches[4];
  } 
  
  phoneValue = phoneValue.replace(/\D/g, '');
  if (phoneValue.length === 0 || phoneValue.length > 10) {
    return;
  }
  if (phoneValue.length < 10)
    phoneValue = ryazanCode + phoneValue;
  
  return '+7' + phoneValue;
}

function addPhoneInput() {
  if (counter.value < maxNumOfPhoneNumbers)
    counter.value++;
}

async function _saveOrgInfo() {
  removeEmptyPhones();
  await getSuggestions();
  try {
    contactInfo.address = addressInfo.addressSuggestions[0].value;
  } catch (error) {
    showError('Выбранный адрес находится вне регионов обслуживания абонентов мобильного приложения. Обратитесь к администратору');
    return;
  }
  let phoneTypes = contactInfo.phoneNumbers.map(ph => defineType(ph));
  phoneTypes = phoneTypes.map(p => p === "phone");
  let validPhones = (phoneTypes.length > 0 && !phoneTypes.includes(false));
  let validEmail =  (!contactInfo.email || defineType(contactInfo.email) === "email");
  let validWeb = (!contactInfo.webSite || defineType(contactInfo.webSite) === "web");
  let validAddress = !contactInfo.address || addressValidation();

  if (!validPhones) {
    showError('Проверьте правильность введённых номеров телефона');
    return;
  }
  if (!validEmail) {
    showError('Проверьте правильность введённого email-адреса. При отсутствии email-адреса оставьте поле пустым');
    return;
  }
  if (!validWeb) {
    showError('Проверьте правильность введённого адреса веб-сайта. При отсутствии web-адреса оставьте поле пустым');
    return;
  }
  if (!validAddress) {
    showError('Проверьте правильность введённого адреса. При отсутствии адреса оставьте поле пустым');
    return;
  }
  await saveInfo();
}

function removeEmptyPhones() {
  contactInfo.phoneNumbers = contactInfo.phoneNumbers.filter(p => p);
  counter.value = contactInfo.phoneNumbers.length;
  if (counter.value === 0)
    counter.value = 1;
}

async function saveInfo() {
  editOrganization();
  let result = await saveOrgInfo(user.value.uid, organization.value);
  if (result === 200 || result.success) {
    showAlert('Данные успешно сохранены');
  } else {
    showError('Не удалось сохранить данные');
  }
}

const alert = ref(null);

function showError(text) {
  alert.value.show(text, true);
}

function showAlert(text) {
  alert.value.show(text, false);
}

function editOrganization() {
  organization.value.address = contactInfo.address;
  organization.value.phoneNumbers = contactInfo.phoneNumbers;
  organization.value.emails[0] = contactInfo.email;
  organization.value.webSite = contactInfo.webSite;
  organization.value.geoXCoordinate = contactInfo.coords[1];
  organization.value.geoYCoordinate = contactInfo.coords[0];
  organization.value.dataSetAutomaticaly = false;
}

async function addressValidation() {
  let result = await getCoordsByAddress(contactInfo.address);
  if (result) {
    return result.qc != 2;
  } else
    return false;
}

function close(e) {
  if (e.target.id !== 'address-input') {
    addressInfo.hideDropdown = true;
  }
}

function toggle() {
  if (!contactInfo.address.isEmpty) {
    showSuggestions();
  }
}

function showSuggestions() {
  getSuggestions();
  if (addressInfo.addressSuggestions.length > 0)
    addressInfo.hideDropdown = false;
}

async function getSuggestions() {
  let result = await getSuggestionsByAddress(contactInfo.address)
  if (result !== false) {
    addressInfo.addressSuggestions = result.suggestions;
  } else {
    showError(result);
  }
}

function updateAddress(suggestion) {
  mapInfo.marker.imageHref = MapPin;
  let selectedAddress = suggestion;
  contactInfo.address = selectedAddress.value;
  previewInfo.addressWithPostIndex = selectedAddress.unrestricted_value;
  contactInfo.coords = [selectedAddress.data.geo_lat, selectedAddress.data.geo_lon];
}

function onMapClick(e) {
  mapInfo.marker.imageHref = MapPin;
  contactInfo.coords = e.get('coords');
  changeAddressFromMap();
}

async function changeAddressFromMap() {
  let mapCoords = {
    lat: contactInfo.coords[0],
    lon: contactInfo.coords[1]
  };
  let result = await getSuggestionsByPosition(mapCoords, 10);
  if (!result.error) {
    let resultAddress = result.suggestions.suggestions[0];
    contactInfo.address = resultAddress.value;
    previewInfo.addressWithPostIndex = resultAddress.unrestricted_value;
  }
}

function phoneState(n) {
  return defineType(contactInfo.phoneNumbers[n]) !== 'phone';
}

function mapOrgIcon() {
  switch(previewInfo.orgIconType) {
    case 0:
      previewInfo.orgIcon = 'icon-org-default.svg';
      break;
    case 3:
      previewInfo.orgIcon = 'icon-heat.svg';
      break;
    case 4:
      previewInfo.orgIcon = 'icon-water.svg';
      break;
    case 9:
      previewInfo.orgIcon = 'icon-electricity.svg';
      break;
    case 10:
      previewInfo.orgIcon = 'icon-fire.svg';
      break;
    case 11:
      previewInfo.orgIcon = 'icon-repair.svg';
      break;
    case 33:
      previewInfo.orgIcon = 'icon-rubbish.svg';
      break;
    default:
      previewInfo.orgIcon = 'icon-org-default.svg';
      break;
  }
}

function openTab(tabName) {
  router.replace({ path: route.path, query: { tab: tabName } })
}

function getImgUrl() {
  return require(`../../assets/img/icons/${previewInfo.orgIcon}`);
}

function formatAddressWithPostIndex() {
  let address = contactInfo.address;
  let search = new RegExp('^([0-9])+,[\\s?\\w+]+');
  if (search.test(address)) {
    contactInfo.address = address.replace(address.match(search)[0], '');
  }
  previewInfo.addressWithPostIndex = address;
}

const emailState = computed(() => defineType(contactInfo.email) === 'email');
const webState = computed(() => defineType(contactInfo.webSite) === 'web');
const emailValidated = computed(() => defineType(contactInfo.email) === 'email' || contactInfo.email === '');
const webValidated = computed(() => defineType(contactInfo.webSite) === 'web' || contactInfo.webSite === '');
const allPhonesAreValid = computed(() => {
  
  let phoneTypes = contactInfo.phoneNumbers.filter(ph => ph);
  phoneTypes = phoneTypes.map(ph => defineType(ph));
  phoneTypes = phoneTypes.map(ph => ph === 'phone');
  return !phoneTypes.includes(false) && phoneTypes.length > 0;
});
const getCurrentTime = computed(() => {
  let dateNow = new Date().toLocaleTimeString();
  let dateStr = dateNow.split(':');
  return dateStr[0] + ':' + dateStr[1];
});

onMounted(async () => {
  let res = await getUser();

  if (res) {
    user.value = res;
    await _getOrgInfo();
    activateTab();
  } else {
    await router.push('/login');
  }
  document.addEventListener('click', close);
});

onUnmounted(() => {
  document.removeEventListener('click', close);
})
</script>

<template>
    <Alert ref="alert"/>
    <bottom-dialog ref="confirm"/>
    <div class="org-info container content d-block">
      <!--Tabs-->
      <ul 
        class="org-info__nav-tabs nav nav-tabs no-border row" 
        id="infoTabs" 
        role="tablist">
        <li 
          class="org-info__nav-item nav-item col-auto" 
          role="presentation">
          <button 
            class="nav-link normal-font font-16 no-border active" 
            aria-controls="contact-info"
            aria-selected="true" 
            id="contact-info-tab"
            type="button" 
            role="tab" 
            @click="openTab('contact-info')"
            data-bs-target="#contact-info" 
            data-bs-toggle="tab">
            Контактные данные
          </button>
        </li>
        <li 
          class="nav-item col-auto" 
          role="presentation">
          <button 
            class="nav-link normal-font font-16 no-border" 
            aria-controls="address"
            aria-selected="false" 
            id="address-tab"
            type="button" 
            role="tab" 
            @click="openTab('address')"
            data-bs-target="#address" 
            data-bs-toggle="tab">
            Адрес офиса
          </button>
        </li>
      </ul>
      <div class="row g-0 flex-nowrap parent-width">
        <div class="col-sm-8 no-horizontal-padding">
          <!--Tab panes-->
          <div v-if="!loader" 
            class="tab-content tabs padding-16 white-background 
              border-radius-4 h-100 d-flex flex-column">
            <!--Organization info pane-->
            <div 
              class="tab-pane active" 
              role="tabpanel" 
              id="contact-info" 
              aria-labelledby="contact-info-tab">
              <div class="container-fluid d-flex flex-column h-100">
                <div class="row no-margin-vertical thin-font">
                  <div class="col-sm-2 align-content-center">
                    <label class="form-label font-12">
                      Телефон*
                    </label>
                  </div>
                  <div class="col-sm-auto d-flex flex-column align-content-center">
                    <label class="form-label font-10 mt-auto">
                      Следует вводить телефонные номера в формате +7
                    </label>
                  </div>
                </div>
                <div class="row align-items-end no-margin-vertical">
                  <ul class="col list-without-style no-margin-vertical">
                    <li 
                      v-for="n in counter" 
                      :key="n">
                      <input 
                        class="form-control" 
                        v-maska="'+7##########'"
                        :class="{'is-invalid': phoneState(n-1)}" 
                        id="phoneNumber{{n}}"
                        v-model="contactInfo.phoneNumbers[n-1]" 
                        placeholder="Телефон"/>
                    </li>
                  </ul>
                  <div class="col align-items-end sticky-button">
                    <a 
                      href="#" 
                      @click="addPhoneInput">
                      <img 
                        class="icon" 
                        src="@/assets/img/icons/icon-add-phone-number.svg" 
                        alt="add-phone" 
                        draggable="false">
                    </a>
                  </div>
                </div>
                <div class="row no-margin-vertical">
                  <div class="col thin-font font-12">
                    <div class="form-group no-margin-vertical">
                      <label class="form-label">
                        E-mail
                      </label>
                      <input 
                        class="form-control" 
                        :class="{'is-invalid': !emailValidated}"
                        id="email" 
                        v-model="contactInfo.email" 
                        type="email"
                        placeholder="E-mail"/>
                    </div>
                    <div class="form-group no-margin-vertical">
                      <label class="form-label">
                        Сайт
                      </label>
                      <input 
                        class="form-control" 
                        :class="{'is-invalid': !webValidated}"
                        id="site" 
                        v-model="contactInfo.webSite" placeholder="Сайт"/>
                    </div>
                    <div class="form-group no-margin-vertical">
                      <label class="form-label">
                        *поле помеченное звездочкой «*», обязательно для заполнения
                      </label>
                    </div>
                  </div>
                  <div class="col"></div>
                </div>
              </div>
            </div>
            <!--Address pane-->
            <div 
              class="tab-pane" 
              id="address" 
              aria-labelledby="address-tab">
              <div class="container-fluid d-flex flex-column h-100">
                <div class="row">
                  <div class="col">
                    <label class="form-label thin-font font-12 no-margin-vertical">
                      Адрес
                    </label>
                    <input 
                      class="form-control" 
                      id="address-input"
                      v-model="contactInfo.address" 
                      placeholder="Адрес"
                      @input="showSuggestions" 
                      @focus="toggle" 
                      autocomplete="off"/>
                    <div 
                      class="dropdown white-background border-radius-4 no-horizontal-padding"
                      :class="{'hidden': addressInfo.hideDropdown}">
                      <ul class="no-horizontal-padding dropdown-list">
                        <li 
                          class="list-without-style no-horizontal-padding"
                          v-for="suggestion in addressInfo.addressSuggestions" 
                          :key="suggestion">
                          <a 
                            class="dropdown-item" 
                            @click="updateAddress(suggestion)" 
                            type="button">
                            {{ suggestion.value }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row d-flex flex-grow-1 flex-shrink-1">
                  <!--Map-->
                  <yandex-map
                    id="yandex-map" 
                    :behaviors="mapInfo.behaviors"
                    :settings="mapInfo.settings" 
                    :coords="contactInfo.coords"
                    :map-events="mapInfo.events" 
                    :options="mapInfo.options"
                    :zoom="mapInfo.zoom" 
                    :controls="mapInfo.controls" 
                    @click="onMapClick">
                    <ymap-marker 
                      сlass="user-select-none" 
                      :coords="contactInfo.coords"
                      marker-id="map-marker" 
                      :hint-content="previewInfo.orgName"
                      :icon="mapInfo.marker"/>
                  </yandex-map>
                </div>
              </div>
            </div>
            <div class="row mt-auto">
              <div class="col-sm-auto">
                <button class="btn btn-primary save padding-12 background-blue border-radius-4"
                  id="save" type="button"
                  @click="_saveOrgInfo">
                  Сохранить
                </button>
              </div>
              <div class="col-sm-4">
                <label 
                  class="form-label thin-font font-12" 
                  for="save">
                  После сохранения данная информация станет сразу доступна пользователям мобильного приложения
                </label>
              </div>
            </div>
          </div>
          <div v-if="loader" 
            class="d-flex justify-content-center 
            align-items-center white-background border-radius-4 h-100">
            <div 
              class="spinner-border" 
              role="status"/>
          </div>
        </div>
        <div 
          id="mobile-preview" 
          class="col-6 col-sm-3 ms-5 no-horizontal-padding">
          <!--Mobile Preview-->
          <div class="container-fluid d-flex mobile-border-layout bg-black no-margin align-items-center">
            <div class="container-fluid d-flex mobile-preview-layout flex-column bg-main user-select-none">
              <div class="row gx-0 mt-auto no-margin-vertical margin-horizontal-14 padding-top-6">
                <div class="col-auto d-flex align-items-end no-margin">
                  <p class="nav-bar time-text font-10 no-margin-vertical">
                    {{getCurrentTime}}
                  </p>
                </div>
                <div class="col row d-flex flex-row-reverse">
                  <div class="col-auto d-flex align-items-end no-margin no-horizontal-padding">
                    <img 
                      class="icon" 
                      src="@/assets/img/icons/icon-battery.svg" 
                      alt="mobile phone battery" 
                      draggable="false">
                  </div>
                  <div class="col-auto d-flex align-items-end no-margin no-horizontal-padding">
                    <img 
                      class="icon" 
                      src="@/assets/img/icons/icon-wifi.svg" 
                      alt="mobile phone wifi" 
                      draggable="false">
                  </div>
                  <div class="col-auto d-flex align-items-end no-margin no-horizontal-padding">
                    <img 
                      class="icon" 
                      src="@/assets/img/icons/icon-cellular.svg" 
                      alt="mobile phone cellular connection" 
                      draggable="false">
                  </div>
                </div>
              </div>
              <div class="row gx-0 mt-auto margin-vertical-14 margin-horizontal-14">
                <div class="col-auto">
                  <img 
                    class="icon" 
                    src="@/assets/img/icons/icon-arrow-left.svg" 
                    alt="mobile phone arrow left" 
                    draggable="false">
                </div>
                <div class="col d-flex justify-content-center align-items-center">
                  <p class="nav-bar big-text font-14 no-margin-vertical">
                    Организация
                  </p>
                </div>
              </div>
              <div class="row gx-1 gy-1 mt-auto margin-horizontal-14 padding-vertical-4 rounded-background bg-white margin-vertical-14">
                <div class="col-auto">
                  <img 
                    class="icon margin-8 icon-20px" 
                    :src="getImgUrl()" 
                    alt="Иконка организации">
                </div>
                <div class="col d-flex align-items-center justify-content-start">
                  <p class="normal-font font-12 text-black no-margin-vertical single-line">
                    {{ previewInfo.orgName }}
                  </p>
                </div>
              </div>
              <div class="row d-flex flex-row justify-content-evenly gx-0 margin-horizontal-14 rounded-background margin-vertical-14 normal-font font-10">
                <div class="org-info__preview-page-title col d-flex justify-content-around align-items-center 
                  bg-white padding-vertical-6">
                  <p class="no-margin-vertical">
                    Обслуживание
                  </p>
                </div>
                <div class="col-1"/>
                <div class="org-info__preview-page-title col d-flex justify-content-center align-items-center 
                  background-blue padding-vertical-6">
                  <p class="text-white no-margin-vertical">
                    Информация
                  </p>
                </div>
              </div>
              <div class="org-info__map-preview-layout d-flex flex-column rounded-background bg-white 
                p-2 margin-horizontal-14 margin-vertical-14">
                <div class="org-info__preview-address d-flex flex-row align-items-center">
                  <img 
                    class="icon icon-20px" 
                    src="@/assets/img/icons/icon-map-pin.svg" 
                    alt="Иконка карты" 
                    draggable="false">
                  <p class="thin-font font-10 text-black mb-0 ms-2">
                    {{ previewInfo.addressWithPostIndex }}
                  </p>
                </div>
                <div class="org-info__preview-map-layout d-flex mt-2
                  border rounded">
                  <yandex-map
                    :behaviors="[]" 
                    @click:=""
                    :settings="mapInfo.settings" 
                    id="yandex-map-static" 
                    :zoom="15" 
                    :controls="[]" 
                    :scroll-zoom="false"
                    :coords="contactInfo.coords"
                    :options="mapInfo.options" >
                  <ymap-marker :coords="contactInfo.coords" marker-id="static-map-marker"
                    :icon="mapInfo.smallMarker" :marker-events="mapInfo.smallMarkerEvents"/>
                  </yandex-map>
                </div>
              </div>
              <div class="row justify-content-evenly gx-0 margin-horizontal-14 margin-top-14">
                <div class="col d-flex rounded-background bg-white justify-content-center padding-12">
                  <img class="icon icon-20px"
                       :class="{'highlight-icon-blue': allPhonesAreValid, 'highlight-icon-gray': !allPhonesAreValid}"
                       src="@/assets/img/icons/icon-phone.svg" draggable="false" id="phoneIcon" alt="" >
                </div>
                <div class="col-1"/>
                <div class="col d-flex rounded-background bg-white justify-content-center padding-12">
                  <img class="icon icon-20px" src="@/assets/img/icons/icon-mail.svg"
                       :class="{'highlight-icon-blue': emailState, 'highlight-icon-gray': !emailState}"
                       draggable="false" id="mailIcon" alt="">
                </div>
                <div class="col-1"/>
                <div class="col d-flex rounded-background bg-white justify-content-center padding-12">
                  <img class="icon icon-20px" src="@/assets/img/icons/icon-web-site.svg"
                       :class="{'highlight-icon-blue': webState, 'highlight-icon-gray': !webState}"
                       draggable="false" id="webIcon" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/pages/org-info.scss';
</style>