function validate(value) {
    value = parseFloat(value);
    if (isNaN(value)) value = "";
    value = String(value);
    if (!value.match(/^[+]?([0-9]*[.,])?[0-9]+$/)) {
        value = "";
    }

    return value;
}

function defineType(data) {
    let emailRegex = /^([a-zA-Z0-9_/./-])+\u0040(([a-zA-Z0-9/-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let phoneRegex = /^(\+7)?\d{10}$/;
    let webRegex = /^(https?:\/\/)?([\da-zа-яё.-]+\.[a-zа-яё.]{2,6}|[\d.]+)([/:?=&#][\da-zа-яё.-]+)*[/?]?$/igm;

    if (emailRegex.test(data)) {
        return "email"
    }
    if (phoneRegex.test(data)) {
        return "phone"
    }
    if (webRegex.test(data)) {
        return "web"
    }
    return false
}

export { validate, defineType };