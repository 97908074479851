import httpClient from '@/api/httpService.js';


function getOrgName(orgId) {
    return httpClient
        .get('api/get-org-info', {
            params: {
                orgId: orgId
            }
        }).then((response) => {
            localStorage.setItem('orgName', response.data.name);
            let result = {};
            result.error = false;
            result.name = response.data.name;
            return result;
        }).catch((e) => {
            let result = {};
            result.error = true;
            result.data = e;
            return result;
        });
}

function getOrgInfo(orgId) {
    return httpClient
        .get('api/get-org-info', {
            params: {
                orgId: orgId
            }
        }).then((response) => {
            let result = response.data;
            result.error = false;
            return result;
        }).catch((e) => {
            let result = {};
            result.error = true;
            result.data = e;
            return result;
        });
}

function saveOrgInfo(userId, organization) {
    return httpClient.post(`api/save-org-info`, organization)
        .then((response) => {
            return response.data;
        })
        .catch((e) => {
            return e;
        });
}

export {
    saveOrgInfo,
    getOrgInfo,
    getOrgName
}