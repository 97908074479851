import axios from "axios"


async function getSuggestionsByPosition(coords, rad) {
    return axios
        .post(
            `${process.env.VUE_APP_DADATA_URL}/suggestions/api/4_1/rs/geolocate/address`,
            {
                lat: coords.lat,
                lon: coords.lon,
                radius: rad,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + process.env.VUE_APP_DADATA_API_KEY,
                }
            }
        )
        .then(response => {
            let result = {};
            result.error = false;
            result.suggestions = response.data;
            return result;
        })
        .catch(error => {
            let result = {};
            result.error = true;
            result.data = error;
            return result;
        });
}

async function getSuggestionsByAddress(input) {
    return axios
        .post(
            `${process.env.VUE_APP_DADATA_URL}/suggestions/api/4_1/rs/suggest/address`,
            {
                query: input,
                language: "ru",
                locations: [
                    {
                        "region": "рязанская"
                    },
                    {
                        "region": "калмыкия"
                    },
                ]
            },
            {
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + process.env.VUE_APP_DADATA_API_KEY,
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

async function getCoordsByAddress(address) {
    return axios
        .post(
            'https://cleaner.dadata.ru/api/v1/clean/address',
            {
                body: JSON.stringify([address])
            },
            {
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + process.env.VUE_APP_DADATA_API_KEY,
                    "X-Secret": process.env.VUE_APP_DADATA_SECRET_KEY
                },
            }
        )
        .then((response) => {
            let result = response;
            result.error = false;
            return result
        })
        .catch((e) => {
            let result = {};
            result.error = true;
            result.data = e;
            return result
        });
}

export {
    getSuggestionsByPosition,
    getSuggestionsByAddress,
    getCoordsByAddress
}